:root {
    --min-page-width: 400px;
    --max-page-width: 400px;
    --max-page-height: 900px;
    --accentblueb-100: rgba(230, 238, 245, 1);
    --accentblueb-300: rgba(193, 211, 229, 1);
    --accentblueb-500: rgba(129, 181, 233, 1);
    --accentgreeng-100: rgba(238, 242, 229, 1);
    --accentgreeng-300: rgba(216, 228, 194, 1);
    --accentgreeng-500: rgba(180, 212, 121, 1);
    --accentpurplep-100: rgba(246, 239, 248, 1);
    --accentpurplep-300: rgba(231, 209, 238, 1);
    --accentpurplep-500: rgba(222, 170, 239, 1);
    --accentredr-100: rgba(243, 230, 231, 1);
    --accentredr-300: rgba(226, 189, 191, 1);
    --accentredr-500: rgba(234, 131, 137, 1);
    --body-10px-regular-font-family: "Inter-Regular", Helvetica;
    --body-10px-regular-font-size: 10px;
    --body-10px-regular-font-style: normal;
    --body-10px-regular-font-weight: 400;
    --body-10px-regular-letter-spacing: 0px;
    --body-10px-regular-line-height: 150%;
    --body-12px-bold-font-family: "Inter-Medium", Helvetica;
    --body-12px-bold-font-size: 12px;
    --body-12px-bold-font-style: normal;
    --body-12px-bold-font-weight: 500;
    --body-12px-bold-letter-spacing: 0px;
    --body-12px-bold-line-height: 150%;
    --body-12px-regular-font-family: "Inter-Regular", Helvetica;
    --body-12px-regular-font-size: 12px;
    --body-12px-regular-font-style: normal;
    --body-12px-regular-font-weight: 400;
    --body-12px-regular-letter-spacing: 0px;
    --body-12px-regular-line-height: 150%;
    --body-14px-bold-font-family: "Inter-Medium", Helvetica;
    --body-14px-bold-font-size: 14px;
    --body-14px-bold-font-style: normal;
    --body-14px-bold-font-weight: 500;
    --body-14px-bold-letter-spacing: 0px;
    --body-14px-bold-line-height: 150%;
    --body-14px-regular-font-family: "Inter-Regular", Helvetica;
    --body-14px-regular-font-size: 14px;
    --body-14px-regular-font-style: normal;
    --body-14px-regular-font-weight: 400;
    --body-14px-regular-letter-spacing: 0px;
    --body-14px-regular-line-height: 150%;
    --body-16px-bold-font-family: "Inter-Medium", Helvetica;
    --body-16px-bold-font-size: 16px;
    --body-16px-bold-font-style: normal;
    --body-16px-bold-font-weight: 500;
    --body-16px-bold-letter-spacing: 0px;
    --body-16px-bold-line-height: 150%;
    --body-16px-regular-font-family: "Inter-Regular", Helvetica;
    --body-16px-regular-font-size: 16px;
    --body-16px-regular-font-style: normal;
    --body-16px-regular-font-weight: 400;
    --body-16px-regular-letter-spacing: 0px;
    --body-16px-regular-line-height: 150%;
    --header-24px-bold-font-family: "Inter-Bold", Helvetica;
    --header-24px-bold-font-size: 24px;
    --header-24px-bold-font-style: normal;
    --header-24px-bold-font-weight: 700;
    --header-24px-bold-letter-spacing: 0px;
    --header-24px-bold-line-height: 150%;
    --header-48px-bold-font-family: "Roboto-Bold", Helvetica;
    --header-48px-bold-font-size: 48px;
    --header-48px-bold-font-style: normal;
    --header-48px-bold-font-weight: 700;
    --header-48px-bold-letter-spacing: 0px;
    --header-48px-bold-line-height: normal;
    --header-48px-medium-font-family: "Roboto-SemiBold", Helvetica;
    --header-48px-medium-font-size: 48px;
    --header-48px-medium-font-style: normal;
    --header-48px-medium-font-weight: 600;
    --header-48px-medium-letter-spacing: 0px;
    --header-48px-medium-line-height: normal;
    --header-48px-regular-font-family: "Roboto-Regular", Helvetica;
    --header-48px-regular-font-size: 48px;
    --header-48px-regular-font-style: normal;
    --header-48px-regular-font-weight: 400;
    --header-48px-regular-letter-spacing: 0px;
    --header-48px-regular-line-height: normal;
    --headings-h1-font-family: "Lora-SemiBold", Helvetica;
    --headings-h1-font-size: 40px;
    --headings-h1-font-style: normal;
    --headings-h1-font-weight: 600;
    --headings-h1-letter-spacing: 0px;
    --headings-h1-line-height: 125%;
    --headings-h2-font-family: "Lora-SemiBold", Helvetica;
    --headings-h2-font-size: 32px;
    --headings-h2-font-style: normal;
    --headings-h2-font-weight: 600;
    --headings-h2-letter-spacing: -1.28px;
    --headings-h2-line-height: 125%;
    --headings-h3-font-family: "Lora-SemiBold", Helvetica;
    --headings-h3-font-size: 24px;
    --headings-h3-font-style: normal;
    --headings-h3-font-weight: 600;
    --headings-h3-letter-spacing: 0px;
    --headings-h3-line-height: 125%;
    --headings-h4-font-family: "Lora-SemiBold", Helvetica;
    --headings-h4-font-size: 18px;
    --headings-h4-font-style: normal;
    --headings-h4-font-weight: 600;
    --headings-h4-letter-spacing: 0px;
    --headings-h4-line-height: 125%;
    --headings-h5-font-family: "Lora-SemiBold", Helvetica;
    --headings-h5-font-size: 14px;
    --headings-h5-font-style: normal;
    --headings-h5-font-weight: 600;
    --headings-h5-letter-spacing: 0px;
    --headings-h5-line-height: 125%;
    --labels-12px-bold-font-family: "Inter-SemiBold", Helvetica;
    --labels-12px-bold-font-size: 12px;
    --labels-12px-bold-font-style: normal;
    --labels-12px-bold-font-weight: 600;
    --labels-12px-bold-letter-spacing: 0px;
    --labels-12px-bold-line-height: 124.00000095367432%;
    --labels-12px-regular-font-family: "Inter-Regular", Helvetica;
    --labels-12px-regular-font-size: 12px;
    --labels-12px-regular-font-style: normal;
    --labels-12px-regular-font-weight: 400;
    --labels-12px-regular-letter-spacing: 0px;
    --labels-12px-regular-line-height: 124.00000095367432%;
    --labels-14px-bold-font-family: "Inter-SemiBold", Helvetica;
    --labels-14px-bold-font-size: 14px;
    --labels-14px-bold-font-style: normal;
    --labels-14px-bold-font-weight: 600;
    --labels-14px-bold-letter-spacing: 0px;
    --labels-14px-bold-line-height: 124.00000095367432%;
    --labels-14px-medium-font-family: "Inter-Medium", Helvetica;
    --labels-14px-medium-font-size: 14px;
    --labels-14px-medium-font-style: normal;
    --labels-14px-medium-font-weight: 500;
    --labels-14px-medium-letter-spacing: 0px;
    --labels-14px-medium-line-height: 124.00000095367432%;
    --labels-14px-regular-font-family: "Inter-Regular", Helvetica;
    --labels-14px-regular-font-size: 14px;
    --labels-14px-regular-font-style: normal;
    --labels-14px-regular-font-weight: 400;
    --labels-14px-regular-letter-spacing: 0px;
    --labels-14px-regular-line-height: 124.00000095367432%;
    --labels-16px-bold-font-family: "Inter-SemiBold", Helvetica;
    --labels-16px-bold-font-size: 16px;
    --labels-16px-bold-font-style: normal;
    --labels-16px-bold-font-weight: 600;
    --labels-16px-bold-letter-spacing: 0px;
    --labels-16px-bold-line-height: 124.00000095367432%;
    --labels-16px-regular-font-family: "Inter-Regular", Helvetica;
    --labels-16px-regular-font-size: 16px;
    --labels-16px-regular-font-style: normal;
    --labels-16px-regular-font-weight: 400;
    --labels-16px-regular-letter-spacing: -0.48px;
    --labels-16px-regular-line-height: 124.00000095367432%;
    --labels-24px-bold-font-family: "Inter-Bold", Helvetica;
    --labels-24px-bold-font-size: 24px;
    --labels-24px-bold-font-style: normal;
    --labels-24px-bold-font-weight: 700;
    --labels-24px-bold-letter-spacing: 0px;
    --labels-24px-bold-line-height: normal;
    --labels-24px-regular-font-family: "Inter-Regular", Helvetica;
    --labels-24px-regular-font-size: 24px;
    --labels-24px-regular-font-style: normal;
    --labels-24px-regular-font-weight: 400;
    --labels-24px-regular-letter-spacing: 0px;
    --labels-24px-regular-line-height: normal;
    --neutraln-100: rgba(246, 246, 246, 1);
    --neutraln-200: rgba(234, 234, 234, 1);
    --neutraln-300: rgba(210, 211, 211, 1);
    --neutraln-400: rgba(177, 178, 178, 1);
    --neutraln-500: rgba(142, 144, 144, 1);
    --neutraln-600: rgba(121, 122, 123, 1);
    --neutraln-700: rgba(87, 89, 90, 1);
    --neutraln-800: rgba(54, 57, 57, 1);
    --neutraln-900: rgba(31, 34, 35, 1);
    --neutralwhite: rgba(255, 255, 255, 1);
    --primaryy-100: rgba(255, 248, 239, 1);
    --primaryy-300: rgba(255, 227, 147, 1);
    --primaryy-500: rgba(249, 210, 98, 1);
  }
  