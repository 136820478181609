.pm-main-page {
    align-items: flex-start;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }
  
  /* .pm-main-page .design-component-instance-node {
    align-self: stretch !important;
  } */
  
  .pm-main-page .parts-list-section {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 2px;
    padding: 16px 0px 0px;
    position: relative;
  }
  
  .pm-main-page .color-icon-vector {
    height: 12px !important;
    position: relative !important;
    width: 12px !important;
  }
  
  .pm-main-page .rule-instance {
    align-self: stretch !important;
    width: 100% !important;
  }
  
  .pm-main-page .plus-instance {
    background-image: url(https://generation-sessions.s3.amazonaws.com/79ca0b56dab8cfe3a6aa6aea6e6849e0/img/plus-2.svg) !important;
    height: 20px !important;
    width: 20px !important;
  }
  